import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Main from "../Layout/Main";
import Helmet from 'react-helmet';
import PreviewCompatibleImage from '../Utils/PreviewCompatibleImage';

import './home-page.scss';

const Home = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query ChimeQuery {
      markdownRemark(frontmatter: { pageId: { eq: "home-page" } }) {
        frontmatter {
          pageTitle
          events {
            title
            link
            logo {
              extension
              publicURL
            }
          }
        }
      }
    }
  `);

  return (
    <Main>
      <Helmet>
        <body className="home-page" />
      </Helmet>
      <section className="content" >
        <h1 className="page-title">{markdownRemark.frontmatter.pageTitle}</h1>
        <a className="event-link event-link__wrapper" href="/vma/">
          <img src="/images/mtv-vma-logo.svg" alt="mtv-vma" />
        </a>
        <a className="event-link event-link__wrapper" href="/cmt/">
          <img src="/images/cmt-logo.svg" alt="cmt" />
        </a>
      </section>
    </Main>
  );
}

export default Home
