import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        markdownRemark(frontmatter: {contentId: {eq: "site-metadata"}}) {
          frontmatter {
            logo {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            siteMetadata {
              description
              title
            }
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}

export default useSiteMetadata
