import React from 'react'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({ imageInfo, customClass }) => {
  const { alt='', childImageSharp, image, extension, publicURL } = imageInfo;
  if (!childImageSharp && extension === 'svg') {
    return <img className={customClass} src={publicURL} alt={alt} />
  }

  // if (typeof imageInfo === 'string') {
  //   // slice is a temp fix for bug in gatsby schema inference when it doesn't recognise file nodes
  //   return <img className={customClass} src={imageInfo.slice(12)} alt={alt} loading="lazy"/>
  // }

  if (!!image && !!image.childImageSharp) {
    return <Img className={customClass}  fluid={image.childImageSharp.fluid} alt={alt} />
  }
    
  if (!!childImageSharp) {
    return <Img className={customClass} fluid={childImageSharp.fluid} alt={alt} />
  }

  return null
}

export default PreviewCompatibleImage
