import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import useSiteNavigation from '../Navigation/SiteNavigation'
import useSiteMetadata from './SiteMetaData'
import { withPrefix } from 'gatsby'

import '../../styles/all.scss'

const Main = ({ children }) => {
  async function loadFonts() {
    const oswald = new FontFace('oswald', 'url(/fonts/Oswald-VariableFont_wght.ttf)', {"weight": "700", "style": "normal", "font-display": "swap"});
    // wait for font to be loaded
    await oswald.load().then(
      () => {
        // add font to document
        document.fonts.add(oswald);
      }
    );
    // enable font with CSS class
    document.body.classList.add('fonts-loaded');
  }

  const AnimatedElements = [
    ".grid-image__container",
    "div.cover__image > picture > img",
    ".animate-on-scroll",
    ".hide",
    ".wipe-right",
    ".text-block__body",
    ".pull-quote__body"
  ];

  const setScrollStatus = () => {
    // a useful number for parallax style animations
    document.body.dataset.scrollStatus = (window.pageYOffset / (document.body.offsetHeight - window.innerHeight)*100);
  }

  const handleScrollAnimations = function(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting && !entry.target.classList.contains("is-visible") ) {
        entry.target.classList.add("is-visible");
      }
    });
  };

  useEffect(() => {
    loadFonts();

    const observerOptions = {
      root: null,
      rootMargin: "-100px",
      threshold: .0025
    }
    const targets = Array.from(document.querySelectorAll(AnimatedElements.toString()));
    const observer = new IntersectionObserver(handleScrollAnimations, observerOptions);

    targets.forEach(function(target) {
      observer.observe(target);
    });
    
    const htmlNode = document.querySelector("html");
    if (htmlNode.scrollHeight > htmlNode.clientHeight) {
      htmlNode.classList.add("hasScrollBar")
      window.addEventListener('scroll', setScrollStatus, false);
    } else if (htmlNode.scrollHeight < htmlNode.clientHeight) {
      htmlNode.classList.remove("hasScrollBar");
    }
  });

  const navigation = useSiteNavigation();
  const { siteMetadata, logo } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{siteMetadata.title}</title>
        <meta name="description" content={siteMetadata.description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={siteMetadata.title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/og-image.png`} />
      </Helmet>
      <Header logo={logo} metaData={siteMetadata}/>
      {children}
      <Footer/>
    </>
  )
}

export default Main
