import React from 'react';
import { Router } from '@reach/router';
import Home from '../components/Home/Home';

import '../styles/all.scss';

const index = () => {

  return (
    <Router basepath="/">
      <Home path="/"/>
    </Router>
  )
};

export default index
