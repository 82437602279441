import React from 'react';
import PreviewCompatibleImage from '../Utils/PreviewCompatibleImage';

import './Header.scss';

const Header = ({metaData, logo}) => {
  return (
    <div className="header-container">
      <header className="header">
        <div className="brand-logo">
          <a href="/" className="brand-logo__link" title={metaData.title}>
            <PreviewCompatibleImage imageInfo={logo} alt={metaData.title}/>
          </a>
        </div>
      </header>
    </div>
  )
}

export default Header;
