import React from 'react';

import './Footer.scss';

const Footer = class extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <footer className="footer">
        <p>{`©${currentYear} Velocity, ViacomCBS`}</p>
      </footer>
    )
  }
}

export default Footer
