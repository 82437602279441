import { graphql, useStaticQuery } from 'gatsby'

const useSiteNavigation = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query SITE_NAVIGATION_QUERY {
        markdownRemark(frontmatter: {pageId: {eq: "navigation"}}) {
          frontmatter {
            navigation {
              route {
                name
                path
              }
            }
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter.navigation
}

export default useSiteNavigation
